export const links = [
	{
		href: "/about",
		text: "About",
	},
	{
		href: "/jobs",
		text: "Jobs",
	},
	{
    	href: "https://studios.moonsworth.com",
    	text: "Studios",
    }
];
