import NextLink from "next/link";
import { useTheme } from "@emotion/react";

type NavItemProps = {
	children: React.ReactNode;
	active?: boolean;
	href: string;
};

export const Link = ({ children, active, href }: NavItemProps) => {
	const isExternal = href.startsWith("http");
	const theme = useTheme();

	return (
		<NextLink href={href} passHref>
			<a
				css={{
					borderBottom: active ? `3px solid ${theme.red}` : "none",
					padding: "4px",
					transition: "background-color 50ms linear",
				}}
			>
				{children}
			</a>
		</NextLink>
	);
};
