import React, { useState } from "react";

import { Link } from "./Link";
import { links } from "../../../data/navbarLinks";
import styled from "@emotion/styled";
import { breakpoints, mq } from "../../../styles/mq";

interface Props {
	isActive(href: string): boolean;
	navActive: boolean;
}

export const DesktopNav = ({ isActive, navActive }: Props) => {
	const Container = styled("ul")`
		margin: 0;
		padding: 0;
		display: none;
		li {
			list-style: none;
			margin: 0 20px;
			padding: 0;
		}

		${mq(breakpoints.lg)} {
			position: initial;
			display: flex;
			align-items: center;
		} ;
	`;

	return (
		<Container>
			{links.map((link) => (
				<li key={link.text}>
					<Link href={link.href} active={isActive(link.href)}>
						{link.text}
					</Link>
				</li>
			))}
		</Container>
	);
};
