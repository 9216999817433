import Link from "next/link";
import styled from "@emotion/styled";

type LinkItemProps = {
	href: string;
	img: string;
	alt: string;
	size: string;
	text: string;
	internal?: boolean;
};

export const ImageLink = ({
	href,
	img,
	alt,
	size,
	internal,
}: LinkItemProps) => {
	const Img = styled.img({ width: size, height: "auto" });
	if (!internal) {
		return (
			<a href={href} target="_blank" rel="noreferrer">
				<Img src={img} alt={alt} />
			</a>
		);
	} else {
		return (
			<Link href={href}>
				<a>
					<Img src={img} alt={alt} />
				</a>
			</Link>
		);
	}
};
