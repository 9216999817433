import styled from "@emotion/styled";
import React from "react";
import { moonsworthLinks } from "../../../data/moonsworthLinks";
import { links } from "../../../data/navbarLinks";
import { socials } from "../../../data/socials";
import { breakpoints, mq } from "../../../styles/mq";
import { ImageLink } from "../../common/imageLink";
import { Logo } from "../../common/logo";
import { SocialLink } from "../../common/socialLink";
import { Link } from "./Link";

interface Props {
	isActive(href: string): boolean;
	navActive: boolean;
}

interface ContainerProps {
	visible: boolean;
}
const Container = styled("ul")<ContainerProps>`
	visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	padding: 0;
	margin: 0;
	background: black url("/images/background.webp") repeat center;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${mq(breakpoints.lg)} {
		display: none;
	}

	li {
		list-style: none;
		margin: 0;
		margin: 10px 0;
		padding: 0;
		text-align: center;
		font-size: 1.5rem;
		&.links {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.moonsworth-links {
				margin: 25px auto;
				justify-content: center;
				max-width: 90%;
				overflow-x: auto;

				&::-webkit-scrollbar {
					display: none;
				}
				
				> a {
					margin: auto 10px;
				}
			}
			&.social-links {
				margin: 25px 0;
				> a {
					margin: 0 10px;
				}
			}
		}
	}
`;

export const MobileNav = ({ isActive, navActive }: Props) => {
	return (
		<Container visible={navActive}>
			<li>
				<Logo size="64px" />
			</li>
			<li>
				<Link href="/" active={isActive("/")}>
					Home
				</Link>
			</li>
			{links.map((link) => (
				<li key={"main-" + link.text}>
					<Link href={link.href} active={isActive(link.href)}>
						{link.text}
					</Link>
				</li>
			))}

			<li className="links moonsworth-links">
				{moonsworthLinks.map((link) => (
					<ImageLink
						key={"links-" + link.href}
						href={link.href ?? "#"}
						img={link.img}
						alt={link.text}
						size={"54px"}
						text={link.text}
						internal={false}
					/>
				))}
			</li>
			<li></li>
			<li className="links social-links">
				{socials.map((social) => (
					<SocialLink
						key={"social-" + social.name}
						image={social.image}
						url={social.url}
						size={"lg"}
						name={social.name}
					/>
				))}
			</li>
		</Container>
	);
};
