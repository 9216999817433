import { useState } from "react";

import { splitLink } from "../../../lib/util";
import { DesktopNav } from "./desktopNav";
import { NavToggle } from "./navToggle";
import { MobileNav } from "./mobileNav";

interface Props {
	path: string;
}

export const Links = (props: Props) => {
	const [navActive, setNavActive] = useState(false);

	const isActive = (href: string) => {
		// Compare the current path to the link in the nav, to see if it is the current page.
		let path = splitLink(props.path);
		let link = splitLink(href);
		return path[0] === link[0];
	};
	return (
		<div className="nav-links">
			<DesktopNav isActive={isActive} navActive={navActive} />
			<MobileNav isActive={isActive} navActive={navActive} />
			<NavToggle
				setNavActive={() => setNavActive(!navActive)}
				navActive={navActive}
			/>
		</div>
	);
};
