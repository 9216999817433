import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

export interface Social {
	name: string;
	url: string;
	image: IconProp;
	size?: SizeProp;
}

const SocialStyled = styled("a")`
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		transition: color 0.2s ease-in-out;
	}

	&:hover {
		svg {
			color: #fff;
		}
	}
`;

export const SocialLink = ({ name, url, image, size }: Social) => (
	<SocialStyled href={url} aria-label={name} target="_blank" rel="noreferrer">
		<FontAwesomeIcon color="#7c7c7c" icon={image} size={size} />
	</SocialStyled>
);
