import styled from "@emotion/styled";
import { breakpoints, mq } from "../../styles/mq";

interface Props {
	text: string;
	origin?: string;
	top?: string;
	left?: string;
}

const Text = styled("div")`
	// Positioning.
	position: absolute;
	transform: rotate(90deg);
	z-index: -10;
	margin: 0;
	padding: 0;
	width: fit-content;
	// Font.
	font-family: Panton, Roboto, Helvetica, sans-serif;
	font-weight: 700;
	font-size: 90px;
	color: transparent;
	letter-spacing: 10px;
	user-select: none;
	// Not supported by every browser, will fallback to being transparent.
	-webkit-text-stroke: 1px #535353;
	display: none;
	${mq(breakpoints.md)} {
		display: block;
	}
`;

export const VerticalText = (props: Props) => {
	return (
		<Text
			role="presentation"
			css={{
				transformOrigin: props.origin || "center",
				top: props.top || "initial",
				left: props.left || "initial",
			}}
			className="vertical-text-container"
		>
			{props.text}
		</Text>
	);
};
