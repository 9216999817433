import Image from "next/image";

interface Props {
	size: string;
}

export const Logo = (props: Props) => {
	return (
		<img
			src="/images/moonsworth-logo.png"
			alt="Moonsworth Logo"
			width={props.size}
			height={props.size}
		/>
	);
};
