import type { AppProps } from "next/app";
import { useRouter } from "next/router";

import { Layout } from "../components/layout/layout";

import Script from "next/script";

import { GTM_ID } from "../lib/gtm";

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();

	const path = router.pathname;

	return (
		<Layout path={path}>
			{/* Google Tag Manager - Global base code */}
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
				}}
			/>
			<Component {...pageProps} />
		</Layout>
	);
}
export default MyApp;
