import React from "react";

import styled from "@emotion/styled";

import { socials } from "../../data/socials";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { moonsworthLinks } from "../../data/moonsworthLinks";
import { ImageLink } from "../common/imageLink";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { SocialLink } from "../common/socialLink";
import ToolTip from "../common/toolTip";
import { breakpoints, mq } from "../../styles/mq";

interface Props {}

interface Social {
	name: string;
	url: string;
	image: IconProp;
	size?: SizeProp;
}

const SidebarStyled = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	background: #0000009d;
	margin: 0;
	padding: 0;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	z-index: 10;
	${mq(breakpoints.md)} {
		display: flex;
	}
	> ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		list-style: none;
		margin: 0;
		margin-top: 5px;
		padding: 20px;
		li {
			&.web-link {
				margin-bottom: 50px;
			}
			&.social-link {
				margin-bottom: 30px;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
`;

export const Sidebar = (props: Props) => {
	return (
		<SidebarStyled>
			<ul>
				{moonsworthLinks.map((link, index) => (
					<ToolTip content={link.text} placement="right" key={index}>
						<li className="web-link" key={link.href}>
							<ImageLink
								href={link.href ?? "#"}
								img={link.img}
								alt={link.alt}
								size={link.size}
								text={link.text}
								internal={false}
							/>
						</li>
					</ToolTip>
				))}
			</ul>

			<ul>
				{socials.map((social: Social) => (
					<ToolTip
						content={social.name}
						placement="right"
						key={social.url}
					>
						<li className="social-link">
							<SocialLink
								image={social.image}
								url={social.url}
								size={social.size || "lg"}
								name={social.name}
							/>
						</li>
					</ToolTip>
				))}
			</ul>
		</SidebarStyled>
	);
};
