import {
	faTwitter,
	faLinkedin,
	faGithub,
} from "@fortawesome/free-brands-svg-icons";

export const socials = [
	{
		name: "Twitter",
		image: faTwitter,
		url: "https://twitter.com/Moonsworth",
	},
	{
		name: "LinkedIn",
		image: faLinkedin,
		url: "https://www.linkedin.com/company/moonsworth/",
	},
	{
		name: "GitHub",
		image: faGithub,
		url: "https://github.com/Moonsworth",
	},
];
