import { Navbar } from "./navbar/navbar";
import { Footer } from "./footer";
import { Sidebar } from "./sidebar";
import { VerticalText } from "../common/verticalText";
import { splitLink } from "../../lib/util";
import { css, Global, ThemeProvider, useTheme } from "@emotion/react";
import { breakpoints, mq } from "../../styles/mq";
import styled from "@emotion/styled";

import { lightTheme, darkTheme } from "../../styles/themes";
import { useState } from "react";

interface Props {
	children: any;
	path: string;
}

export const Layout = (props: Props) => {
	const [currentTheme, setCurrentTheme] = useState(darkTheme);
	const theme = useTheme();
	const isHomepage = props.path === "/";

	// Get the full path as an array, getting the last element as the page name.
	const pathSplit = splitLink(props.path);
	const pageName = pathSplit[0];

	const VerticalTextContainer = styled("div")`
		padding: 0;
		margin: 0;
		line-height: 80px;
	`;

	return (
		<ThemeProvider theme={currentTheme}>
			<Global
				styles={css`
					@font-face {
						font-family: Panton;
						src: url("/fonts/Panton-LightCaps.woff2");
						font-weight: 300;
						font-style: normal;
						font-display: fallback;
					}
					@font-face {
						font-family: Panton;
						src: url("/fonts/Panton-BlackCaps.woff2");
						font-weight: 700;
						font-style: normal;
						font-display: fallback;
					}
					* {
						font-family: Roboto, sans-serif;
					}
					*,
					*::after,
					*::before {
						box-sizing: border-box;
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						font-smoothing: antialiased;
					}
					body {
						margin: 0;
						padding: 0;
						color: ${currentTheme.light};
						min-height: 100vh;
						background: ${currentTheme.dark}
							url("/images/background.webp") repeat center;
						transition: color 0.2s ease-out,
							background-color 0.2s ease-out;
					}
					main {
						max-width: 1680px;
						margin: 0 auto;
						line-height: 1.5;
					}
					h1,
					h2,
					h3,
					h4,
					h5,
					h6,
					a {
						font-family: Panton, Roboto, Helvetica, sans-serif;
						font-weight: 700;
						letter-spacing: 2px;
					}
					a {
						color: inherit;
						text-decoration: none;
					}
					.brand-slider-container {
						margin-top: 150px;
						display: block;
						${mq(breakpoints.lg)} {
							display: grid;
							grid-template-columns: 2fr 5fr;
							grid-gap: 1px;
						}
					}
					.embla {
						overflow-x: clip;
						overflow-y: visible;
						padding: 20px;
						${mq(breakpoints.lg)} {
							padding: initial;
						}
					}
					.embla__container {
						display: flex;
						height: 100%;
						width: 320px;
					}
					.embla__slide {
						position: relative;
						flex: 0 0 100%;
						margin-right: 50px;
						overflow-y: visible;
						height: 500px;
						${mq(breakpoints.lg)} {
							height: unset;
						}
					}
					.relative {
						position: relative;
					}
					.nav-links {
						display: flex;
						align-items: center;

						@media (max-width: 992px) {
							.nav-toggle {
								display: flex !important;
								align-items: center;
								justify-content: center;
							}
						}

						.nav-toggle {
							position: relative;
							border-radius: 10px;
							background: none;
							border: 2px solid ${currentTheme.light};
							color: white;
							font-family: system-ui, sans-serif;
							font-size: 1rem;
							line-height: 1.2;
							white-space: nowrap;
							text-decoration: none;
							cursor: pointer;
							z-index: 10;
							width: 50px;
							height: 50px;
							display: none;

							.cross-container {
								span {
									position: relative;
									display: block;
									width: 30px;
									height: 4px;
									background: ${currentTheme.light};
									border-radius: 10px;
									z-index: 1;
									margin: 0 auto 5px auto;
									transform-origin: -1px 100%;
									&:last-child {
										margin-bottom: 0;
									}
									transition: transform 0.5s
											cubic-bezier(0.77, 0.2, 0.05, 1),
										background 0.5s
											cubic-bezier(0.77, 0.2, 0.05, 1),
										opacity 0.55s ease;
								}
								&.nav-active {
									span {
										&:first-of-type {
											transform: rotate(45deg)
												translate(0px, -5px);
										}
										&:nth-of-type(2) {
											opacity: 0;
										}
										&:last-of-type {
											transform: rotate(-45deg)
												translate(3px, 5px);
										}
									}
								}
							}
						}
					}
				`}
			/>

			<Navbar isHomepage={isHomepage} path={props.path} />

			{!isHomepage && (
				<VerticalTextContainer>
					<VerticalText
						text={pageName}
						origin="left bottom"
						top="20px"
						left="17.5px"
					/>
				</VerticalTextContainer>
			)}

			{props.children}

			{!isHomepage ? <Footer /> : <Sidebar />}
		</ThemeProvider>
	);
};
