export const lightTheme = {
	light: "#FFF",
	dark: "#000",
	dark100: "#191919",
	dark200: "rgb(17,17,17)",
	gray: "#7c7c7c",
	red: "#B45553",
	blue: "#5495ba",
	green: "#64ba61",
};

export const darkTheme = {
	light: "#FFF",
	dark: "#000",
	dark100: "#191919",
	dark200: "rgb(17,17,17)",
	gray: "#7c7c7c",
	red: "#B45553",
	blue: "#5495ba",
	green: "#64ba61",
};
