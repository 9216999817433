import * as Sentry from '@sentry/nextjs';

const IGNORED_EXCEPTIONS = [
  /clarity\.js/i,
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /extensions\//i,
  /^chrome:\/\//i,
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /^(chrome|safari)-extension:\/\//i,
  /gtm\.js/i,
  /AbortError: The operation was aborted./,
  /AbortError: The play() request was interrupted by a call to pause()./,
  /The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission./,
  /The fetching process for the media resource was aborted by the user agent at the user\/s request./,
  /Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type/,
  /top.GLOBALS/,
  /originalCreateNotification/,
  /canvas.contentDocument/,
  /MyApp_RemoveAllHighlights/,
  /Can\/t find variable: ZiteReader/,
  /jigsaw is not defined/,
  /ComboSearch is not defined/,
  /atomicFindClose/,
  /fb_xd_fragment/,
  /bmi_SafeAddOnload/,
  /EBCallBackMessageReceived/,
  /conduitPage/,
  /Script error./,
  /Cannot read properties of undefined (reading 'sendMessage')/,
  /TypeError: Cannot read properties of undefined (reading 'sendMessage')/,
  /ResizeObserver loop limit exceeded/,
  /ResizeObserver loop completed with undelivered notifications/,
  /Can\/t execute code from a freed script/,
  /\bmoz-extension:\/\//,
  /reCAPTCHA placeholder element must be an element or id/,
  /UnhandledRejection: "Timeout"/,
  /Bootstrap\/s JavaScript requires jQuery/,
  /\/metrics\.js\b/,
  /should_do_lastpass_here/,
  /\/analytics\.js/i,
]

function shouldIgnoreException(s) {
  return s && IGNORED_EXCEPTIONS.find(pattern => pattern.test(s));
}

Sentry.init({
  dsn: "https://226c229b76374bd0a34a83cf96821b82@o923049.ingest.sentry.io/6246048",
  tracesSampleRate: 0,
  beforeSend: (event, hint) => {
    if (shouldIgnoreException(hint?.originalException?.message)) return null
    if (shouldIgnoreException(hint?.originalException?.stack)) return null

    if (hint?.stack?.stack?.find(s => shouldIgnoreException(s.url))) return null

    return event;
  }
});