import React from "react";
import { socials } from "../../data/socials";
import styled from "@emotion/styled";
import { SocialLink, Social } from "../common/socialLink";
import { useTheme } from "@emotion/react";

export const Footer = () => {
	const theme = useTheme();
	const FooterStyled = styled("footer")`
		width: 100%;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: ${theme.gray};
		padding-bottom: 50px;
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			transition: color 0.15s ease-in-out;
			&:hover {
				color: ${theme.light};
			}
		}

		.socials {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 20px 0;
			padding: 0;

			li {
				list-style: none;
				margin: auto 10px;
			}
		}
	`;

	return (
		<FooterStyled>
			<ul className="socials">
				{socials.map((social: Social) => (
					<li className="social-link" key={social.url}>
						<SocialLink
							image={social.image}
							url={social.url}
							size={social.size || "lg"}
							name={social.name}
						/>
					</li>
				))}
			</ul>
			<a>
				&copy; Moonsworth, LLC {new Date().getFullYear()}
			</a>
		</FooterStyled>
	);
};
