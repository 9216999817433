export const moonsworthLinks = [
	{
		href: "https://www.lunarclient.com/?utm_source=moonsworth-site&utm_medium=sidebar&utm_campaign=lead",
		img: "/images/logos/lunarclient.png",
		alt: "Lunar Client Logo",
		size: "50px",
		text: "Lunar Client",
	},
	{
		href: "https://skins.mcstats.com/?utm_source=moonsworth-site&utm_medium=sidebar&utm_campaign=lead",
		img: "/images/logos/mcstats.png",
		alt: "MCStats Logo",
		size: "50px",
		text: "MCStats",
	},
	{
		href: "https://www.resourcepacks.gg/?utm_source=moonsworth-site&utm_medium=sidebar&utm_campaign=lead",
		img: "/images/logos/resourcepacks.png",
		alt: "Resourcepacks Logo",
		size: "50px",
		text: "Resourcepacks",
	}
];
