import styled from "@emotion/styled";
import Tippy, { TippyProps } from "@tippyjs/react";

import { lightTheme, darkTheme } from "../../styles/themes";

const TippyStyled = styled(Tippy)<TippyProps>`
	display: inline-block;
	background-color: ${darkTheme.dark100};
	padding: 10px 15px;
	border-radius: 25px;
	font-weight: bold;

	&[data-state="hidden"] {
		opacity: 0;
		transform: translateY(-50%);
	}
`;

const ToolTip = (props: TippyProps) => {
	const { children, theme, ...tippyProps } = props;

	return <TippyStyled {...tippyProps}>{props.children}</TippyStyled>;
};

export default ToolTip;
