import Link from "next/link";
import styled from "@emotion/styled";
import { Links } from "./links";
import { Logo } from "../../common/logo";
import { breakpoints, mq } from "../../../styles/mq";

interface Props {
	isHomepage: Boolean;
	path: string;
}

export const Navbar = ({ isHomepage, path }: Props) => {
	const Nav = styled("nav")`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		padding: 20px;
		font-weight: bold;
		position: relative;
		transition: transform 5s ease-in-out;
		z-index: 2;

		${mq(breakpoints.md)} {
			.moonsworth-logo {
				transform: ${isHomepage
					? "translateX(calc(50vw - 50% - 18px))"
					: "none"};
			}
		}
	`;

	return (
		<Nav>
			<Link href="/">
				<a className="moonsworth-logo">
					<Logo size="64px" />
				</a>
			</Link>

			<Links path={path} />
		</Nav>
	);
};
