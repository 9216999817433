interface Props {
	setNavActive(): any;
	navActive: boolean;
}

export const NavToggle = ({ setNavActive, navActive }: Props) => {
	return (
		<button
			className="nav-toggle"
			onClick={setNavActive}
			aria-label="navigation toggle"
		>
			<div
				className={`cross-container nav-${navActive ? "active" : ""} `}
			>
				<span />
				<span />
				<span />
			</div>
		</button>
	);
};
